export const leftSidebarDisplaySettings = [
    'live-classes',
    'live-courses',
    'batches-list',
    'batch-sechdule',
    'course-path-design',
    'schedule-classes',
    'batch-subjects',
    'teaching-management',
    'enrolled-course',
    'assignment-design',
    'new-course-detail-design',
    'message-box',
];