import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ORGANIZATION_STAFF_ROLE_ENUM, USER_POSSIBLE_ONLINE_STATUS } from '@teacherapp_core/common/constants';
import { commonErrorHandler } from '@teacherapp_core/common/func';
import { IUserOnlineStatus } from '@teacherapp_core/interfaces/channel-data.interface';
import { SocketRealtimeCommunicationService } from '@teacherapp_core/services/_socket_services/socket-realtime-communication.service';
import { DataService } from '@teacherapp_core/services/data.service';
import { RestApiService } from '@teacherapp_core/services/rest-api.service';
import { environment } from '@teacherapp_env/environment';
import { ToastMessageBarService } from '@teacherapp_shared/utils-library/toast-message-bar/toast-message-bar.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-top-navbar-default',
  templateUrl: './top-navbar-default.component.html',
  styleUrls: ['./top-navbar-default.component.scss']
})
export class TopNavbarDefaultComponent implements OnInit, OnDestroy {

  @Input() currentUserData: any;
  @Input() currentUserRoleAsStaff: string;

  userStatusInfo: IUserOnlineStatus = null;
  staffRoleEnums = ORGANIZATION_STAFF_ROLE_ENUM;

  env = environment;
  imagePath = this.env.cdn_urls.image;

  userOnlineStatusOptions: any[] = USER_POSSIBLE_ONLINE_STATUS;

  subscriptions: Subscription[] = [];

  constructor(
    private dataService: DataService,
    private apiService: RestApiService,
    private toastMessageBarService: ToastMessageBarService,
    private socketRealTImeCommService: SocketRealtimeCommunicationService,
  ) { }

  show_main_nav() {
    document.getElementById('overlay-superbook').classList.toggle('open');
    document.body.classList.toggle('hide-main-nav');
    document.body.classList.remove('show-rightPanel');
    document.body.classList.remove('show-filter-panel');
  }

  doChangeUserOnlineStatus(_online_status_info: any) {
    if (!this.currentUserData) {
      return;
    }
    let _payload = {
      user_id: this.currentUserData.id,
      online_status: _online_status_info.id,
    };

    this.apiService.addUserAvailbilityStatus(_payload).subscribe(
      (response: any) => {
        this.currentUserData = Object.assign({}, this.currentUserData, { member_status: response });
        this.dataService.updateMyCurrentOnlineStatus(response);
        this.socketRealTImeCommService.sendUserOnlineStatusModificationInGlobalRoom({ action_mode: 'UPDATE', status_feed: response });
      },
      (error: any) => {
        const err = commonErrorHandler(error);
        this.toastMessageBarService.openSnackbar({ heading: 'Channel Member Management', message: err }, 'ERROR');
      }
    )
  }

  listenForUserStatusModificationEvent() {
    this.subscriptions.push(
      this.socketRealTImeCommService.newGlobalRoomReceivedEvent$.subscribe(
        (response: any) => {
          // console.log('newGlobalRoomReceivedEvent$ Top Navbar', response);
          if (response) {
            if (response.action_type === 'RECEIVED_USER_STATUS_MODIFY_EVENT') {
              if (this.currentUserData.id === response.status_feed.user_id) {
                this.userStatusInfo = response.status_feed;
                this.currentUserData.online_status = response.status_feed.online_status;
              }
            }
            this.socketRealTImeCommService.updateNewGlobalRoomReceivedEventHandler(null);
          }
        }
      )
    )
  }

  ngOnInit(): void {
    this.listenForUserStatusModificationEvent();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

}
