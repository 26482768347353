export const dynamicPageSettings: any[] = [
    {
        page: 'login',
        componentName: 'LoginComponent',
        moduleName: 'LoginModule',
        isAuthenticated: false,
    },
    {
        page: 'forgot-password',
        componentName: 'ForgotPasswordComponent',
        moduleName: 'ForgotPasswordModule',
        isAuthenticated: false,
    },
    {
        page: 'reset-password',
        componentName: 'ResetPasswordComponent',
        moduleName: 'ResetPasswordModule',
        isAuthenticated: false,
    },
    {
        page: 'live-class-preview',
        componentName: 'LiveClassPreviewComponent',
        moduleName: 'LiveClassPreviewModule',
        isAuthenticated: true,
    },
    {
        page: 'org_selection',
        componentName: 'OrgSelectionDialogComponent',
        moduleName: 'OrgSelectionDialogModule',
        isAuthenticated: true,
    },
    {
        page: 'team_selection',
        componentName: 'TeamSelectionDialogComponent',
        moduleName: 'TeamSelectionDialogModule',
        isAuthenticated: true,
    },
];

export const publicDynamicPages = [
    'login',
    'forgot-password',
    'reset-password'
];
