import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeftNavbarDefaultComponent } from './left-navbar-default.component';
import { RouterModule } from '@angular/router';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';



@NgModule({
  declarations: [
    LeftNavbarDefaultComponent
  ],
  imports: [
    CommonModule,
    NgScrollbarModule,
    MatMenuModule,
    MatIconModule,
    RouterModule,
  ],
  exports: [
    LeftNavbarDefaultComponent
  ]
})
export class LeftNavbarDefaultModule { }
