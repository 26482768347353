import { Injectable } from '@angular/core';
import { environment } from '@teacherapp_env/environment';

@Injectable({
  providedIn: 'root'
})
export class DynamicStylesLoaderService {

  env = environment;

  constructor() { }

  loadStyle(styleName: string) {
    return new Promise((resolve, reject) => {
      const head = document.getElementsByTagName('head')[0];
      const _hashed_version = this.env.version;
      let themeLink = document.getElementById(styleName) as HTMLLinkElement;

      // if (themeLink) {
      //   const _previous_version = themeLink.dataset.version;
      //   if (_previous_version !== _hashed_version) {
      //     head.removeChild(themeLink);
      //     themeLink = null;
      //   }
      // }
  
      if (!themeLink) {
        const style = document.createElement('link');
        style.id = `${styleName}`;
        style.rel = 'stylesheet';
        style.href = `${styleName}`;
        style.dataset.version = _hashed_version;
        head.appendChild(style);
  
        style.onload = () => {
          console.log(styleName + ' loaded');
          resolve(true);
        };

        style.onerror = () => {
          resolve(true);
        };
      }
      else {
        resolve(true);
      }
    })
  }

  async loadAllStyles(stylePaths: string[]) {
    let _promise_arr = [];
    stylePaths.forEach((eachStyle) => _promise_arr.push(this.loadStyle(eachStyle)));
    return await Promise.all(_promise_arr);
  }

}
