import { NgxUiLoaderConfig, POSITION, SPINNER } from "ngx-ui-loader";

export const NGX_UI_LOADER_DEFAULT_CONFIG: NgxUiLoaderConfig = {
    "blur": 1,
    "delay": 0,
    "fastFadeOut": true,
    "fgsColor": "red",
    "fgsPosition": POSITION.centerCenter,
    "fgsSize": 70,
    "fgsType": SPINNER.cubeGrid,
    "gap": 24,
    "logoPosition": POSITION.bottomRight,
    "logoSize": 120,
    "logoUrl": "assets/images/icons/doerdo-logo-icon.png",
    "masterLoaderId": "master",
    "overlayBorderRadius": "0",
    "overlayColor": "rgba(40,40,40,0)",
    "pbColor": "#cd08f9",
    "pbDirection": "ltr",
    "pbThickness": 2,
    "hasProgressBar": true,
    "text": "",
    "textColor": "#FFFFFF",
    "textPosition": POSITION.centerCenter,
    "maxTime": -1,
    "minTime": 300
};