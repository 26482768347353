<div class="snackbar-notification">
    <div class="snackbar-messages">
        <h3>{{ headingMessage | titlecase }}</h3>
        <p *ngIf="singleMessage && messageBodyAsHtml === false">{{ singleMessage }}</p>
        <p *ngIf="singleMessage && messageBodyAsHtml" [innerHTML]="singleMessage"></p>
        <span *ngIf="footerMessageText">{{ footerMessageText }}</span>
    </div>
    <button *ngIf="visibleCloseButton" class="closer_notification_bell" (click)="closeSnackbar()">
        <em class="material-icons">
            close
        </em>
    </button>
</div>