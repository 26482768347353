import { version } from './version';
export const environment = {
  production: false,
  version: version,
  configMode: 'LOCAL_SERVE',
  // base_url: 'https://dev3.doersgo.com/api',
  base_url: 'https://a3.clinilead.com/api',
  // base_url: 'https://api.doerdo.com/api',
  // base_url: 'https://valued-teal-officially.ngrok-free.app/api',
  cdn_urls: {
    image: 'https://content.doerdo.com/',
    video: 'https://video.doerdo.com/',
    white_board_content: 'https://wb.doerdo.com/', 
    recording: 'https://recording.doerdo.com/',
    class_asset: 'https://wb.doerdo.com/class_assets/', 
    import_files: 'https://dd2-files.s3.ap-southeast-1.amazonaws.com/qf_imported/',
    chat_attachment_files: 'https://dd2-files.s3.ap-southeast-1.amazonaws.com/message_attachment/',
    general: 'https://wb.doerdo.com/class_assets/',
  },
  socket: {
    base_url: 'https://s2.clinilead.com',
    base_path: '/socket.io/',
  },
  // AWS: {
  //   BUCKET: 'cliniled',
  //   ACCESS_KEY: '<ACCESS_KEY',
  //   SECRET_ACCESS_KEY: '<SECRET_ACCESS_KEY>',
  //   UPLOAD_PATH: 'https://cliniled.s3.amazonaws.com/',
  //   IMAGE_PATH: 'upload/',
  //   VIDEO_PATH: 'videos/',
  //   IMPORT_PATH: 'imports/',
  //   CLASS_ASSETS_PATH: 'class_assets/',
  //   CLASS_RECORDING_DOMAIN: 'https://doerdo-classes.s3.ap-southeast-1.amazonaws.com/',
  //   CLASS_RECORDING_PATH: 'classrecording/',
  // },
  syncfusionLicenseKey: 'Ngo9BigBOggjHTQxAR8/V1NHaF1cWWhIfEx1RHxQdld5ZFRHallYTnNWUj0eQnxTdEZiWH1fcHdRQ2JbV0N1Vg==',
  // tzDB: {
  //   apiEndPoint: 'https://api.timezonedb.com/v2.1',
  //   apiKey: '5XGK5LOPVQEH'
  // },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out idn production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
