import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckVariableTypePipe } from './check-variable-type.pipe';

@NgModule({
  declarations: [
    CheckVariableTypePipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    CheckVariableTypePipe
  ]
})
export class CheckVariableTypeModule { }
