import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TopNavbarDefaultModule } from './shared/components/top-navbar-default/top-navbar-default.module';
import { LeftNavbarDefaultModule } from './shared/components/left-navbar-default/left-navbar-default.module';
import { CoreModule } from '@teacherapp_core/core.module';
import { registerLicense } from '@syncfusion/ej2-base';
import { environment } from '@teacherapp_env/environment';

registerLicense(environment.syncfusionLicenseKey);


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    TopNavbarDefaultModule,
    LeftNavbarDefaultModule,
    CoreModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
