import { Component, OnInit, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-toast-message-bar',
  templateUrl: './toast-message-bar.component.html',
  styleUrls: ['./toast-message-bar.component.scss']
})
export class ToastMessageBarComponent implements OnInit {

  visibleCloseButton = true;
  headingMessage = '';
  singleMessage = '';
  snackbarMode = '';
  messageBodyAsHtml = false;
  footerMessageText = '';

  constructor(
    private snackbarRef: MatSnackBarRef<ToastMessageBarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) private data: any,
  ) {
    this.visibleCloseButton = ('closeButton' in this.data) ? this.data.closeButton : false;
    this.snackbarMode = this.data.mode;
    this.singleMessage = (('message' in this.data) && typeof(this.data.message) === 'string') ? this.data.message : 'message';
    this.messageBodyAsHtml = ('messageAsHtml' in this.data) ? this.data.messageAsHtml : false;
    this.headingMessage = ('heading' in this.data) ? this.data.heading : this.snackbarMode;
    this.footerMessageText = ('footerMessage' in this.data) ? this.data.footerMessage : '';
  }

  closeSnackbar() {
    this.snackbarRef.dismiss();
  }

  ngOnInit(): void {
  }

}
