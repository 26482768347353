import { AfterViewInit, Compiler, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { dynamicCSSFileSettings } from '@teacherapp_core/common/dynamic-css-settings';
import { dynamicPageSettings } from '@teacherapp_core/common/dynamic-page-settings';
import { leftSidebarDisplaySettings } from '@teacherapp_core/common/leftsidebar-display-settings';
import { DataService } from '@teacherapp_core/services/data.service';
import { DynamicStylesLoaderService } from '@teacherapp_core/services/dynamic-styles-loader.service';
import { LocalService } from '@teacherapp_core/services/local.service';
import { environment } from '@teacherapp_env/environment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {

  env = environment;
  selectedDynamicPage = '';
  dynamicComponentRefs: any = {
    login: {},
    'forgot-password': {},
    'reset-password': {},
    'live-class-preview': {},
    'org_selection': {},
    'team_selection': {},
  };
  loadDynamicComponentsList: any[] = dynamicPageSettings;
  loadDynamicCssStylesList: any[] = dynamicCSSFileSettings;
  leftSidebarVisible = leftSidebarDisplaySettings;
  subscriptions: Subscription[] = [];

  currentUserData: any = null;
  currentUserRoleAsStaff = null;
  leftSidebarisHidden = true;

  constructor(
    private swUpdate: SwUpdate,
    private compiler: Compiler,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dataService: DataService,
    private localService: LocalService,
    private dynamicStylesLoaderService: DynamicStylesLoaderService,
  ) {
    this.checkAndUpdateCurrentUserData();
  }

  /**
   * lazy load dynamic component
   * @param componentData 
   */
  async loadComponent(componentData: any) {
    if(!this.dynamicComponentRefs[componentData.page].component && !this.dynamicComponentRefs[componentData.page].module) {
      let componentImportRef: any = null;
      let moduleImportRef: any = null;
      if(componentData.page === 'login') {
        componentImportRef = await import('@teacherapp_pages/login/login.component');
        moduleImportRef = await import('@teacherapp_pages/login/login.module');
      }
      else if(componentData.page === 'forgot-password') {
        componentImportRef = await import('@teacherapp_pages/forgot-password/forgot-password.component');
        moduleImportRef = await import('@teacherapp_pages/forgot-password/forgot-password.module');
      }
      else if(componentData.page === 'reset-password') {
        componentImportRef = await import('@teacherapp_pages/reset-password/reset-password.component');
        moduleImportRef = await import('@teacherapp_pages/reset-password/reset-password.module');
      }
      else if(componentData.page === 'live-class-preview') {
        componentImportRef = await import('@teacherapp_pages/live-class-preview/live-class-preview.component');
        moduleImportRef = await import('@teacherapp_pages/live-class-preview/live-class-preview.module');
      }
      else if(componentData.page === 'org_selection') {
        componentImportRef = await import('@teacherapp_pages/login/org-selection-dialog/org-selection-dialog.component');
        moduleImportRef = await import('@teacherapp_pages/login/org-selection-dialog/org-selection-dialog.module');
      }
      else if(componentData.page === 'team_selection') {
        componentImportRef = await import('@teacherapp_pages/login/team-selection-dialog/team-selection-dialog.component');
        moduleImportRef = await import('@teacherapp_pages/login/team-selection-dialog/team-selection-dialog.module');
      }
      const componentRef = componentImportRef[componentData.componentName];
      this.dynamicComponentRefs[componentData.page]['module'] = await this.compiler.compileModuleAsync(moduleImportRef[componentData.moduleName]);
      this.dynamicComponentRefs[componentData.page]['component'] = componentRef;
      console.log(componentData.page + " Component LOAD completed");
    }
    else {
      console.log(componentData.page + ' Component ALREADY LOADED');
    }
  }

  async loadSpecificComponent() {
    const componentSetting = this.loadDynamicComponentsList.find(eachComp => eachComp.page === this.selectedDynamicPage);
    if(componentSetting && this.checkComponentCanLoad(componentSetting)) {
      await this.loadComponent(componentSetting);
      this.dataService.updateDynamicPageSelection(componentSetting.page);
    }
  }

  checkComponentCanLoad(componentSettings: any) {
    const authenticationStatus = this.localService.checkIsAuthenticated();
    if(componentSettings.isAuthenticated === false && authenticationStatus === false) {
      return true;
    }
    else if(componentSettings.isAuthenticated === true && authenticationStatus === true) {
      return true;
    }
    console.log(componentSettings.page + ' Component CAN NOT BE LOADED');
    return false;
  }

  async loadAllDynamicComponents() {
    for(const component of this.loadDynamicComponentsList) {
      if(this.checkComponentCanLoad(component)) {
        await this.loadComponent(component);
      }
    }
  }

  async loadAllDynamicStyles() {
    if (environment.configMode != 'LOCAL_SERVE') {
      for(const _css_file of this.loadDynamicCssStylesList) {
        await this.dynamicStylesLoaderService.loadStyle(_css_file.fileurl);
      }
    }
  }

  checkAndUpdateCurrentUserData() {
    this.dataService.updateCurrentUserData(this.localService.getUserDataLocalStorage());
  }

  remove_nav() {
    document.body.classList.add("hide-main-nav");
    document.getElementById('overlay-superbook')?.classList.remove('open');
  }

  listenForRouterEventChange() {
    this.subscriptions.push(
      this.router.events.subscribe(
        (event) => {
          if (event instanceof NavigationEnd) {
            // console.log({ event });
            if (leftSidebarDisplaySettings.find((eachUrl) => event.urlAfterRedirects.indexOf(eachUrl) >- 0)) {
              this.leftSidebarisHidden = false;
            } 
            else {
              this.leftSidebarisHidden = true;
            }
            if(this.leftSidebarisHidden) {
              document.body.classList.remove('show-top-left-bar');
            }
            else {
              document.body.classList.add('show-top-left-bar');
            }
          }
        }
      )
    );
  }

  listenForQueryParamsChange() {
    this.subscriptions.push(
      this.activatedRoute.queryParams.subscribe(
        (response) => {
          this.selectedDynamicPage = response.page ? response.page : '';
          console.log(this.selectedDynamicPage);
          this.currentUserRoleAsStaff = this.localService.getOrganizationSpecificStaffRole(response.organization_id);
          if(this.selectedDynamicPage) {
            this.loadSpecificComponent();
          }
          else {
            this.dataService.updateDynamicPageSelection('');
          }
        }
      )
    );
  }

  listenForCurrentUserDataChange() {
    this.subscriptions.push(
      this.dataService.currentUserData$.subscribe(
        (response) => {
          this.currentUserData = response ? response.user : null;
          if(response) {
            document.body.classList.remove('guest-mode');
          }
          else {
            document.body.classList.add('guest-mode');
          }
        }
      )
    );
  }

  listenForSoftwareVersionUpdate() {
    this.subscriptions.push(
      this.swUpdate.available.subscribe((evt) => {
        console.log('evt', evt);
        if (confirm("New version available! OK to refresh?")) {
          window.location.reload();
        }
      })
    );
  }

  showAppVersion() {
    const basicCss = 'padding: 8px; font-size: 15px; font-weight: 700';
    const headingColor = 'background: #511CD9;' + basicCss;
    const versionColor = 'background: #138bee;' + basicCss;
    console.log('%cApp Version' + `%cv-${this.env.version}`, headingColor, versionColor);
  }

  showAppConfigMode() {
    const basicCss = 'padding: 8px; font-size: 15px; font-weight: 700';
    const headingColor = 'background: #ff587e;' + basicCss;
    const versionColor = 'background: #1b71f5;' + basicCss;
    console.log('%cConfig Mode' + `%c${this.env.configMode}`, headingColor, versionColor);
  }

  ngOnInit(): void {
    this.showAppVersion();
    this.showAppConfigMode();
    this.listenForSoftwareVersionUpdate();
    this.listenForRouterEventChange();
    this.listenForQueryParamsChange();
    this.listenForCurrentUserDataChange();
  }
  
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.loadAllDynamicComponents();
      this.loadAllDynamicStyles();
    }, 10000);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
