import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PrivateGuard } from '@teacherapp_core/guards/private.guard';
import { DynamicStyleLoaderResolver } from '@teacherapp_core/resolvers/style-loader/dynamic-style-loader.resolver';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'teaching-management',
    pathMatch: 'full'
  },
  {
    path: 'teaching-management',
    loadChildren: () => import('@teacherapp_pages/teaching-management-layout/teaching-management-layout.module').then(m => m.TeachingManagementLayoutModule),
    resolve: {
      styleLoader: DynamicStyleLoaderResolver,
    },
    data: {
      stylePaths: [
        'course-item.css',
        'grade-data-menu.css',
      ],
    },
    runGuardsAndResolvers: 'always',
    // canActivate: [
    //   PrivateGuard
    // ],
  },
  {
    path: 'channel-management',
    loadChildren: () => import('@teacherapp_pages/channel-commnuication-layout/channel-commnuication-layout.module').then(m => m.ChannelCommnuicationLayoutModule),
  },
  {
    path: 'online-classroom',
    loadChildren: () => import('@teacherapp_pages/online-class-page/online-class-page.module').then(m => m.OnlineClassPageModule),
    canActivate: [
      PrivateGuard
    ],
    // runGuardsAndResolvers: 'always',
  },
  {
    path: 'live-class-recorder',
    loadChildren: () => import('@teacherapp_pages/online-class-recorder-page/online-class-recorder-page.module').then(m => m.OnlineClassRecorderPageModule),
  },
  {
    path: 'video-class-design',
    loadChildren: () => import('@teacherapp_pages/video-class-design/video-class-design.module').then(m => m.VideoClassDesignModule),
    // canActivate: [
    //   PrivateGuard
    // ],
    // runGuardsAndResolvers: 'always',
  },
  {
    path: 'course-path-design',
    loadChildren: () => import('@teacherapp_pages/design-course-path/design-course-path.module').then(m => m.DesignCoursePathModule),
    // canActivate: [
    //   PrivateGuard
    // ],
    // runGuardsAndResolvers: 'always',
  },
  {
    path: 'assignment-design',
    loadChildren: () => import('@teacherapp_pages/designs/design-assignment-view/design-assignment-view.module').then(m => m.DesignAssignmentViewModule),
  },
  {
    path: 'new-course-detail-design',
    loadChildren: () => import('@teacherapp_pages/designs/design-course-info-view/design-course-info-view.module').then(m => m.DesignCourseInfoViewModule),
  },
  {
    path: '',
    loadChildren: () => import('@teacherapp_router/left-filter-layout-view/left-filter-layout-view.module').then(m => m.LeftFilterLayoutViewModule),
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
