import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UserAvatarPicUiComponent } from './user-avatar-pic-ui.component';
import { CheckVariableTypeModule } from '@teacherapp_core/pipes/check-variable-type/check-variable-type.module';
import { GetRandomColorCodeModule } from '@teacherapp_core/pipes/get-random-color-code/get-random-color-code.module';
import { GetNameInitialModule } from '@teacherapp_core/pipes/get-name-initial/get-name-initial.module';

@NgModule({
  declarations: [
    UserAvatarPicUiComponent
  ],
  imports: [
    CommonModule,
    MatTooltipModule,
    CheckVariableTypeModule,
    GetRandomColorCodeModule,
    GetNameInitialModule,
  ],
  exports: [
    UserAvatarPicUiComponent
  ]
})
export class UserAvatarPicUiModule { }
