import { Pipe, PipeTransform } from '@angular/core';
import { generateRandomColor, getColorCodeFromInitials } from '@teacherapp_core/common/func';

@Pipe({
  name: 'getRandomColorCode'
})
export class GetRandomColorCodePipe implements PipeTransform {

  transform(value: any, actionMode: 'RANDOM' | 'NAME_SPECIFIC' = 'RANDOM'): string {
    if (actionMode === 'NAME_SPECIFIC') {
      return getColorCodeFromInitials(value.profile_name ? value.profile_name : '');
    }
    return generateRandomColor();
  }

}
