import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TEACHING_FILTER_OPTIONS } from '@teacherapp_core/common/filter-area.constant';
import { TEACHING_FILTER_TYPE } from '@teacherapp_core/interfaces/filter-area.interface';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  dynamicPageSelected = new BehaviorSubject<string>('');
  dynamicPageSelected$ = this.dynamicPageSelected.asObservable();

  currentUserData = new BehaviorSubject<any>(null);
  currentUserData$ = this.currentUserData.asObservable();

  selectedClassDetails = new BehaviorSubject<any>(null);
  selectedClassDetails$ = this.selectedClassDetails.asObservable();

  isOnlineClassRunning = false;

  forceEndClassIdentifier = new BehaviorSubject<any>(null);
  forceEndClassIdentifier$ = this.forceEndClassIdentifier.asObservable();

  anotherDeviceConnctedClassData = new BehaviorSubject<boolean>(false);
  anotherDeviceConnctedClassData$ = this.anotherDeviceConnctedClassData.asObservable();

  filtersAppliedTeaching = new BehaviorSubject<any[]>([]);
  filtersAppliedTeaching$ = this.filtersAppliedTeaching.asObservable();

  requestForFilterFromTab = new BehaviorSubject<any>(null);
  requestForFilterFromTab$ = this.requestForFilterFromTab.asObservable();

  selectedDateFromMySchedule = new BehaviorSubject<any>(null);
  selectedDateFromMySchedule$ = this.selectedDateFromMySchedule.asObservable();

  orgClassTreeSuggestions = new BehaviorSubject<any>(null);
  orgClassTreeSuggestions$ = this.orgClassTreeSuggestions.asObservable();

  refreshPerticularOrganizationSuggestion = new BehaviorSubject<any>(null);
  refreshPerticularOrganizationSuggestion$ = this.refreshPerticularOrganizationSuggestion.asObservable();

  allPossibleFilters = TEACHING_FILTER_OPTIONS;

  overallPageFocusLevel = new BehaviorSubject<any>(null);
  overallPageFocusLevel$ = this.overallPageFocusLevel.asObservable();

  organizationSuggestionDataFetched = new BehaviorSubject<any>(null);
  organizationSuggestionDataFetched$ = this.organizationSuggestionDataFetched.asObservable();

  lastSelectedOrganizationId = new BehaviorSubject<any>(null);
  lastSelectedOrganizationId$ = this.lastSelectedOrganizationId.asObservable();

  lastSelectedTeamId = new BehaviorSubject<any>(null);
  lastSelectedTeamId$ = this.lastSelectedTeamId.asObservable();

  filterDisabledStatus = new BehaviorSubject<any>(null);
  filterDisabledStatus$ = this.filterDisabledStatus.asObservable();

  teamPageFilterDisabledStatus = new BehaviorSubject<any>(null);
  teamPageFilterDisabledStatus$ = this.teamPageFilterDisabledStatus.asObservable();

  myOwnOnlineStatusChangeEvent = new BehaviorSubject<any>(null);
  myOwnOnlineStatusChangeEvent$ = this.myOwnOnlineStatusChangeEvent.asObservable();

  constructor() { }

  updateDynamicPageSelection(pageName: string) {
    this.dynamicPageSelected.next(pageName);
  }

  updateCurrentUserData(userData: any) {
    this.currentUserData.next(userData);
  }

  updateClassDetails(classDetails: any) {
    this.selectedClassDetails.next(classDetails);
  }

  forceEndClassByRouteChange(endStatus: any) {
    this.forceEndClassIdentifier.next(endStatus);
  }

  updateAnotherDeviceConnctedSameClassInfo(deviceInfo: boolean) {
    this.anotherDeviceConnctedClassData.next(deviceInfo);
  }

  updateSelectedFilterFromTeachingSection(filters: any[]) {
    this.filtersAppliedTeaching.next(filters);
  }

  updateSelectedFilterFromSpecificTab(filters: any) {
    this.requestForFilterFromTab.next(filters);
  }

  updateSelectedDateFromMySchedule(date: any) {
    this.selectedDateFromMySchedule.next(date);
  }

  updateOrgClassTreeSuggestions(suggestions: any) {
    this.orgClassTreeSuggestions.next(suggestions);
  }

  updaterefreshOrgSuggestion(info: any) {
    this.refreshPerticularOrganizationSuggestion.next(info);
  }

  updateHighestLevelFocus(focusLevel: any) {
    this.overallPageFocusLevel.next(focusLevel);
  }

  updateOrganizationSuggestionFetched(suggestionDataFetched: boolean) {
    this.organizationSuggestionDataFetched.next(suggestionDataFetched);
  }

  updateLastSelectedOrganizationId(_org_id: number) {
    this.lastSelectedOrganizationId.next(_org_id);
  }

  updateLastSelectedTeamId(_team_id: number) {
    this.lastSelectedTeamId.next(_team_id);
  }

  updateCurrentFilterDisabledStatus(_filter_ids: any[]) {
    this.filterDisabledStatus.next(_filter_ids);
  }

  updateCurrentTeamPageFilterDisabledStatus(_filter_ids: any[]) {
    this.teamPageFilterDisabledStatus.next(_filter_ids);
  }

  updateMyCurrentOnlineStatus(_status_feed: any) {
    this.myOwnOnlineStatusChangeEvent.next(_status_feed);
  }

  getPerticularTeachingFilterInfo(filterType: TEACHING_FILTER_TYPE) {
    return this.allPossibleFilters.find((eachFilter) => eachFilter.type === filterType);
  }

  findAllDependentFilterOnParentItem(filterType: TEACHING_FILTER_TYPE) {
    const _filterOptions = JSON.parse(JSON.stringify(TEACHING_FILTER_OPTIONS));
    return _filterOptions.filter((eachFilOption) => eachFilOption.parents.indexOf(filterType) >= 0).map((eachSortFilter) => eachSortFilter.type);
  }

  clearAllChildFilterFormField(filterType: TEACHING_FILTER_TYPE, filtersAllowed: any[], fg: FormGroup) {
    let childFormFields = this.findAllDependentFilterOnParentItem(filterType);
    childFormFields.forEach((eachFilter) => {
      if (filtersAllowed.indexOf(eachFilter) >= 0) {
        fg.get(eachFilter).setValue([]);
        fg.get(eachFilter).updateValueAndValidity();
      }
    });
  }

  getScrollBottomValue(targetELem: any) {
    return targetELem.scrollHeight - targetELem.scrollTop - 55;
  }

  infiniteScrollHandling(targetELem: any, bottomScrollConfig: any) {
    // console.log(targetELem.scrollTop, targetELem.offsetHeight)
    if (targetELem.scrollTop <= bottomScrollConfig.scrollOffset) {
      if ((targetELem.scrollHeight - targetELem.scrollTop) > (bottomScrollConfig.lastTopPosition + bottomScrollConfig.scrollOffset)) {
        return { topReached: true, top: targetELem.scrollTop, scrolledFromBottom: targetELem.scrollHeight - targetELem.scrollTop };
      }
    }
    else {
      if ((targetELem.scrollHeight - (targetELem.offsetHeight + bottomScrollConfig.scrollOffset)) <= targetELem.scrollTop) {
        if (targetELem.scrollTop > (bottomScrollConfig.lastPosition + bottomScrollConfig.scrollOffset)) {
          return { bottomReached: true, bottom: targetELem.scrollTop }
        }
      }
    }
    return null;
  }

  infiniteScrollHandling2(targetELem: any, scrollConfig: any, parentElementRef: any = null) {
    const scrollDirection = targetELem.scrollTop > scrollConfig.lastScrollTop ? 'down' : 'up';
    const lastScrollTop = targetELem.scrollTop;
    const lastScrollBottom = targetELem.scrollHeight - lastScrollTop;
    let lastLoadPositionUp = 0;
    let lastLoadPositionDown = 0;

    let scrollEnabled = true;
    if (parentElementRef) {
      const _parent_element_reference = targetELem.closest(parentElementRef);
      if (_parent_element_reference.scrollHeight >= targetELem.scrollHeight) {
        scrollEnabled = false;
      }
      else{
        scrollEnabled = true;
      }
    }

    if (scrollDirection === 'up' && (lastScrollTop - scrollConfig.lastLoadPositionUp) < scrollConfig.scrollOffset) {
      // Call your API to load more data, then update lastLoadPositionUp
      lastLoadPositionUp = lastScrollTop;
      return { topReached: true, lastScrollTop: lastScrollTop, lastLoadPositionUp: lastLoadPositionUp, scrollEnabled };
    }

    if (scrollDirection === 'down' && (targetELem.scrollHeight - lastScrollTop - scrollConfig.lastLoadPositionDown) < scrollConfig.scrollOffset) {
      // Call your API to load more data, then update lastLoadPositionDown
      lastLoadPositionDown = lastScrollBottom;
      return { bottomReached: true, lastScrollTop: lastScrollTop, lastScrollBottom: lastScrollBottom, lastLoadPositionDown: lastLoadPositionDown, scrollEnabled };
    }
    return { lastScrollTop: lastScrollTop, lastScrollBottom: lastScrollBottom, scrollEnabled };
  }

}
