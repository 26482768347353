import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { DynamicStylesLoaderService } from '@teacherapp_core/services/dynamic-styles-loader.service';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DynamicStyleLoaderResolver implements Resolve<boolean> {

  constructor(
    private dynamicStylesLoaderService: DynamicStylesLoaderService,
  ) {

  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const _dynamic_style_paths = route.data.stylePaths ? route.data.stylePaths : [];

    if (route.data.stylePaths.length === 0) {
      return of(true);
    }
  
    this.dynamicStylesLoaderService.loadAllStyles(_dynamic_style_paths).then(() => {
      return of(true);
    });
  }
}
