import { ITeachingFilter } from "@teacherapp_core/interfaces/filter-area.interface";

export const TEACHING_PARENT_CHILD_BACKEND_KEY_MAPPING = {
    organization_id: [],
    branch_id: ['organization_id', 'team_id'],
    course_id: ['organization_id', 'branch_id', 'team_id'],
    batch_id: ['organization_id', 'branch_id', 'course_id', 'team_id'],
    subject_id: ['organization_id', 'branch_id', 'course_id', 'batch_id', 'team_id'],
    grade_id: ['organization_id', 'branch_id', 'course_id', 'team_id'],
    grade_subject_id: ['organization_id', 'branch_id', 'course_id', 'batch_id', 'subject_id', 'team_id'],
    teacher_id: ['organization_id', 'branch_id', 'course_id', 'batch_id', 'subject_id', 'team_id'],
    student_id: ['organization_id', 'branch_id', 'course_id', 'batch_id', 'team_id'],
    class_id: ['organization_id', 'branch_id', 'course_id', 'batch_id', 'subject_id', 'team_id'],
    team_id: ['organization_id'],
    staff_id: ['organization_id', 'branch_id', 'course_id', 'batch_id', 'subject_id', 'team_id'],
    org_students: ['organization_id', 'branch_id', 'course_id', 'batch_id', 'subject_id', 'team_id'],
    team_member_id: ['organization_id'],
};

export const TEACHING_FILTER_TYPE_BACKEND_KEYWORDS = {
    organization_id: 'organization',
    branch_id: 'branch',
    course_id: 'courses',
    batch_id: 'batch',
    subject_id: 'batch_subject',
    grade_id: 'grades',
    grade_subject_id: 'subjects',
    teacher_id: 'teachers',
    student_id: 'students',
    class_id: 'class',
    team_id: 'teams',
    staff_id: 'org_staff',
    org_students: 'org_students',
};

export const TEACHING_FILTER_TYPE_BACKEND_CURRENT_KEY = {
    organization_id: 'org_id',
    branch_id: 'branch_id',
    course_id: 'course_id',
    batch_id: 'batch_id',
    subject_id: 'batch_subject_id',
    class_id: 'class_id',
    grade_id: 'grade_id',
    grade_subject_id: 'subject_id',
    teacher_id: 'teachers',
    student_id: 'students',
    team_id: 'team_id',
    staff_id: 'org_staff_id',
    org_students: 'org_students',
};

export const TEACHING_FILTER_OPTIONS: ITeachingFilter[] = [
    {
        type: 'organization_id',
        name: 'Organization',
        icon: 'assets/images/svg/organization.svg',
        filterScope: 'MAIN',
        hasDropdown: true,
        hasMultiselect: false,
        allowAutocomplete: true,
        dataSource: 'LOCAL',
        displayKey: 'org_name',
        backendParamKey: 'org_id',
        filterShow: false,
        allowClear: false,
        parents: [],
        dependentChildren: [ 'branch_id', 'course_id', 'batch_id', 'subject_id', 'grade_id', 'grade_subject_id', 'teacher_id', 'student_id', 'staff_id' ],
        nonDependentChildren: [],
        visible: 'ALL',
        noOptionSelectedLabel: 'Select any',
        rank: 1,
    },
    {
        type: 'branch_id',
        name: 'Branch',
        icon: 'assets/images/svg/branch-blue.svg',
        filterScope: 'MAIN',
        hasDropdown: true,
        hasMultiselect: false,
        allowAutocomplete: true,
        dataSource: 'SERVER',
        displayKey: 'branch_name',
        backendParamKey: 'branch_id',
        filterShow: true,
        allowClear: true,
        parents: [ 'organization_id' ],
        dependentChildren: [ 'course_id', 'batch_id', 'subject_id' ],
        nonDependentChildren: [ 'grade_id', 'grade_subject_id', 'teacher_id', 'student_id' ],
        visible: 'ALL',
        noOptionSelectedLabel: 'All',
        rank: 3,
    },
    {
        type: 'course_id',
        name: 'Course',
        icon: 'assets/images/svg/courses.svg',
        filterScope: 'MAIN',
        hasDropdown: true,
        hasMultiselect: false,
        allowAutocomplete: true,
        dataSource: 'SERVER',
        displayKey: 'course_name',
        backendParamKey: 'course_id',
        filterShow: true,
        allowClear: true,
        parents: [ 'organization_id', 'branch_id' ],
        dependentChildren: [ 'batch_id', 'subject_id' ],
        nonDependentChildren: [ 'grade_id', 'grade_subject_id', 'teacher_id', 'student_id' ],
        visible: 'ALL',
        noOptionSelectedLabel: 'All',
        rank: 4,
    },
    {
        type: 'batch_id',
        name: 'Batch',
        icon: 'assets/images/svg/batch.svg',
        filterScope: 'MAIN',
        hasDropdown: true,
        hasMultiselect: false,
        allowAutocomplete: true,
        dataSource: 'SERVER',
        displayKey: 'batch_name',
        backendParamKey: 'batch_id',
        filterShow: true,
        allowClear: true,
        parents: [ 'organization_id', 'branch_id', 'course_id' ],
        dependentChildren: [ 'subject_id' ],
        nonDependentChildren: [ 'grade_id', 'grade_subject_id', 'teacher_id', 'student_id' ],
        visible: 'ALL',
        noOptionSelectedLabel: 'All',
        rank: 5,
    },
    {
        type: 'subject_id',
        name: 'Batch Subject',
        icon: 'assets/images/svg/subject.svg',
        filterScope: 'MAIN',
        hasDropdown: true,
        hasMultiselect: false,
        allowAutocomplete: true,
        dataSource: 'SERVER',
        displayKey: 'course_subject_name',
        backendParamKey: 'batch_subject_id',
        filterShow: true,
        allowClear: true,
        parents: [ 'organization_id', 'branch_id', 'course_id', 'batch_id' ],
        dependentChildren: [],
        nonDependentChildren: [ 'grade_id', 'grade_subject_id', 'teacher_id', 'student_id' ],
        visible: 'ALL',
        noOptionSelectedLabel: 'All',
        rank: 6,
    },
    {
        type: 'team_id',
        name: 'Organization Team',
        icon: 'assets/images/svg/team-icon.svg',
        filterScope: 'GLOBAL',
        hasDropdown: true,
        hasMultiselect: false,
        allowAutocomplete: true,
        dataSource: 'SERVER',
        displayKey: 'team_name',
        backendParamKey: 'team_id',
        filterShow: false,
        allowClear: false,
        parents: [],
        dependentChildren: [ 'branch_id', 'course_id', 'batch_id', 'subject_id', 'grade_id', 'grade_subject_id', 'teacher_id', 'student_id', 'staff_id' ],
        nonDependentChildren: [],
        visible: 'ALL',
        noOptionSelectedLabel: 'Select any',
        rank: 2,
    },
    {
        type: 'grade_id',
        name: 'Grade',
        icon: 'assets/images/svg/grade-warning.svg',
        filterScope: 'GLOBAL',
        hasDropdown: true,
        hasMultiselect: true,
        allowAutocomplete: true,
        dataSource: 'SERVER',
        displayKey: 'grade_name',
        backendParamKey: 'grade_id',
        filterShow: true,
        allowClear: true,
        parents: [],
        dependentChildren: [],
        nonDependentChildren: [],
        visible: 'ALL',
        noOptionSelectedLabel: 'All',
        rank: 7,
    },
    {
        type: 'grade_subject_id',
        name: 'Grade Subject',
        icon: 'assets/images/svg/grade-subject-warning.svg',
        filterScope: 'GLOBAL',
        hasDropdown: true,
        hasMultiselect: true,
        allowAutocomplete: true,
        dataSource: 'SERVER',
        displayKey: 'subject_name',
        backendParamKey: 'subject_id',
        filterShow: true,
        allowClear: true,
        parents: [],
        dependentChildren: [],
        nonDependentChildren: [],
        visible: 'ALL',
        noOptionSelectedLabel: 'All',
        rank: 8,
    },
    {
        type: 'teacher_id',
        name: 'Teacher',
        icon: 'assets/images/svg/teacher-warning.svg',
        filterScope: 'GLOBAL',
        hasDropdown: true,
        hasMultiselect: true,
        allowAutocomplete: true,
        dataSource: 'SERVER',
        displayKey: 'name',
        backendParamKey: 'teachers',
        filterShow: true,
        allowClear: true,
        parents: [],
        dependentChildren: [],
        nonDependentChildren: [],
        visible: 'manager',
        noOptionSelectedLabel: 'All',
        rank: 9,
    },
    {
        type: 'staff_id',
        name: 'Organization Staff',
        icon: 'assets/images/svg/staff-red-icon.svg',
        filterScope: 'GLOBAL',
        hasDropdown: true,
        hasMultiselect: true,
        allowAutocomplete: true,
        dataSource: 'SERVER',
        displayKey: 'staff_name',
        backendParamKey: 'staff_id',
        filterShow: true,
        allowClear: true,
        parents: [],
        dependentChildren: [],
        nonDependentChildren: [],
        visible: 'ALL',
        noOptionSelectedLabel: 'All',
        rank: 10,
    },
    {
        type: 'student_id',
        name: 'Student',
        icon: 'assets/images/svg/student-warning.svg',
        filterScope: 'GLOBAL',
        hasDropdown: true,
        hasMultiselect: true,
        allowAutocomplete: true,
        dataSource: 'SERVER',
        displayKey: 'name',
        backendParamKey: 'students',
        filterShow: true,
        allowClear: true,
        parents: [],
        dependentChildren: [],
        nonDependentChildren: [],
        visible: 'ALL',
        noOptionSelectedLabel: 'All',
        rank: 11
    }
];

export const COURSE_ITEM_PARENT_DEPTHS = {
    'organization_id': 0,
    'branch_id': 1,
    'course_id': 2,
    'course_type_id': 2,
    'batch_id': 3,
    'subject_id': 4,
    'class_id': 5
};

export const PARENT_ITEM_DEPTH_KEY_VALUE_FROM_ORG_SUBJECT = {
    0: {
        type: 'organization_id',
        name: 'Organization',
        icon: 'assets/images/svg/organization.svg',
        nextField: null,
        currentField: 'org_id',
        labelField: 'org_name',
        routeSettings: {
            segments: [ 'organization' ],
        },
    },
    1: {
        type: 'branch_id',
        name: 'Branch',
        icon: 'assets/images/svg/branch-blue.svg',
        nextField: 'org_id',
        currentField: 'branch_id',
        labelField: 'branch_name',
        routeSettings: {
            segments: [ 'branch' ],
        },
    },
    2: {
        type: 'course_id',
        name: 'Course',
        icon: 'assets/images/svg/courses.svg',
        nextField: 'branch_id',
        currentField: 'course_id',
        labelField: 'name',
        routeSettings: {
            segments: [ 'courses' ],
        },
    },
    3: {
        type: 'batch_id',
        name: 'Batch',
        icon: 'assets/images/svg/batch.svg',
        nextField: 'course_id',
        currentField: 'live_course_batch',
        labelField: 'name',
        routeSettings: {
            segments: [ 'batch' ],
        },
    },
    4: {
        type: 'subject_id',
        name: 'Subject',
        icon: 'assets/images/svg/subject.svg',
        nextField: 'live_course_batch',
        currentField: 'live_course_batch_subject',
        labelField: 'batch_subject_name',
        routeSettings: {
            segments: [ 'subject' ],
        },
    }
};

export const PARENT_ITEM_DEPTH_KEY_VALUE = {
    ...PARENT_ITEM_DEPTH_KEY_VALUE_FROM_ORG_SUBJECT,
    5: {
        type: 'class_id',
        name: 'Class',
        icon: 'assets/images/svg/class.svg',
        nextField: 'live_course_batch_subject',
        currentField: null,
        labelField: 'id',
        routeSettings: {
            segments: [ 'classes' ],
        },
    }
};