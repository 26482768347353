import { UpperCasePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getNameInitial'
})
export class GetNameInitialPipe implements PipeTransform {

  transform(full_name: string, ...args: unknown[]): unknown {
    let _initial_text = '';
    const _name_array = (full_name ? full_name.split(' ') : []).filter((_name_item) => (_name_item && _name_item.trim() != ''));
    if (_name_array.length > 0) {
      if (_name_array[0]) {
        _initial_text += _name_array[0][0];
      }
      if (_name_array.length > 1) {
        _initial_text += _name_array[_name_array.length - 1][0];
      }
  
      if (_initial_text.length === 1) {
        if (_name_array[0].length > 1) {
          _initial_text += _name_array[0][1];
        }
      }
    }
    return new UpperCasePipe().transform(_initial_text);
  }

}
