import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'checkVariableType'
})
export class CheckVariableTypePipe implements PipeTransform {

  transform(value: any, variable_type: string = 'object'): boolean {
    if (variable_type === 'object' && typeof value === 'object' && !Array.isArray(value) && value !== null) {
      return true;
    }
    return false;
  }

}
