import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '@teacherapp_core/services/data.service';
import { LocalService } from '@teacherapp_core/services/local.service';
import { MAT_MENU_DEFAULT_OPTIONS } from '@angular/material/menu';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-left-navbar-default',
  templateUrl: './left-navbar-default.component.html',
  styleUrls: ['./left-navbar-default.component.scss'],
  providers: [
    {
      provide: MAT_MENU_DEFAULT_OPTIONS,
      useValue: {
        overlayPanelClass: 'app-nav-panel'
      }
    }
  ]
})
export class LeftNavbarDefaultComponent implements OnInit, OnDestroy {

  @Input() currentUserData: any;
  
  currentPageFocusLevel = null;
  subscriptions: Subscription[] = [];

  constructor(
    private localService: LocalService,
    private dataService: DataService,
  ) { }

  checkClassExists(elem: HTMLElement, className: string) {
    return elem.classList.contains(className);
  }

  toggleDropMenu(event: any) {
    event.target.closest('.drop-menu-item-set').classList.toggle('show-menu');
  }
  
  toggleLeftNavMenu(event: any) {
    event.target.closest('.app-nav-container').classList.toggle('app-nav-collapse');
  }

  async doLogout() {
    this.localService.removeAllUserDataFromLocalStorage();
    this.dataService.updateCurrentUserData(null);
    await this.localService.redirectToHomePage();
    location.reload();
  }

  menuOpened() {
    setTimeout(function(){
      let testH = document.getElementsByClassName('mat-menu-panel')[0].clientHeight;
      console.log(testH);

    },100);
  }

  listenForHighestLevelOfFilter() {
    this.subscriptions.push(
      this.dataService.overallPageFocusLevel$.subscribe(
        (response: any) => {
          if (response) {
            this.currentPageFocusLevel = this.dataService.getPerticularTeachingFilterInfo(response.type);
          }
          else {
            this.currentPageFocusLevel = null;
          }
        }
      )
    );
  }

  ngOnInit(): void {
    this.listenForHighestLevelOfFilter();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

}
