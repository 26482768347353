import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GetRandomColorCodePipe } from './get-random-color-code.pipe';

@NgModule({
  declarations: [
    GetRandomColorCodePipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    GetRandomColorCodePipe
  ],
})
export class GetRandomColorCodeModule { }
