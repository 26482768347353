import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@teacherapp_env/environment';

@Injectable({
  providedIn: 'root'
})
export class RestApiService {

  env = environment;
  baseUrl = this.env.base_url;
  // tzUrl = this.env.tzDB.apiEndPoint;
  // tzApiKey = this.env.tzDB.apiKey;

  constructor(
    private http: HttpClient
  ) {

  }

  teacherLogin(data: object) {
    return this.http.post(`${this.baseUrl}/org/login`, data);
  }

  forgotTeacherPassword(data: object) {
    return this.http.post(`${this.baseUrl}/register/send-reset-password-link/`, data);
  }

  resetPassword(data: object) {
    return this.http.post(`${this.baseUrl}/register/set-reset-password/`, data);
  }

  fetchOrganizationRelatedMyAssignedTeam(params: any) {
    return this.http.get(`${this.baseUrl}/org/get_team`, { params });
  }
  
  fetchChildrenOfLearningPath(lpId: number) {
    return this.http.get(`${this.baseUrl}/get_child_data?id=${lpId}`);
  }
  
  fetchLiveClassesList(teacherId: any) {
    return this.http.get(`${this.baseUrl}/list_live_classes/?teacher=${teacherId}`);
  }

  fetchSpecificClassDetails(classId: number) {
    return this.http.get(`${this.baseUrl}/get_live_class/${classId}`);
  }

  fetchSpecificRecordClassDetails(classId: number) {
    return this.http.get(`${this.baseUrl}/get_live_class_info/${classId}`);
  }

  generateRTMTokenByUidFromServer(data: any) {
    return this.http.post(`${this.baseUrl}/get_rtm_details`, data);
  }

  generateRTMTokenRecorderByUidFromServer(data: any) {
    return this.http.post(`${this.baseUrl}/rtm_for_channel_recorder`, data);
  }

  generateRTCTokenByUidFromServer(data: any) {
    return this.http.post(`${this.baseUrl}/teacher_valid_or_not`, data);
  }

  saveClassMessage(OBJ: any) {
    return this.http.post(`${this.baseUrl}/class_messages`, OBJ);
  }

  getClassMessagesList(classid: any) {
    return this.http.get(`${this.baseUrl}/class_messages?classid=${classid}`);
  }
  
  updateClassAttendance(OBJ: any) {
    return this.http.post(`${this.baseUrl}/add_attendence`, OBJ);
  }

  changeClassStatus(OBJ: any) {
    return this.http.put(`${this.baseUrl}/update_class_status`, OBJ);
  }

  updateOnlineClassLogs(OBJ: any) {
    return this.http.post(`${this.baseUrl}/live_class_action`, OBJ);
  }

  generateRTCTokenForRecordingFromServer(data: any) {
    return this.http.post(`${this.baseUrl}/live_class_record`, data);
  }

  updateClassHost(OBJ: any) {
    return this.http.put(`${this.baseUrl}/update_host`, OBJ);
  }

  updateFocusedStudentScreen(OBJ: any) {
    return this.http.post(`${this.baseUrl}/selected_student_screen`, OBJ);
  }

  updateClassLiveAttendanceCount(OBJ: any) {
    return this.http.post(`${this.baseUrl}/live_class_attendence`, OBJ);
  }

  startLiveClassRecording(OBJ: any) {
    return this.http.post(`${this.baseUrl}/class_record_start`, OBJ);
  }

  endLiveClassRecording(OBJ: any) {
    return this.http.post(`${this.baseUrl}/class_record_end`, OBJ);
  }

  getStudentAndTeachersList(val:any,type:any){
    return this.http.get(`${this.baseUrl}/add_new_classes?pattern=`+val+`&user_type=`+type);
  }

  getStudentsById(val:any, type:any){
    return this.http.get(`${this.baseUrl}/add_new_classes?sid=`+val+`&user_type=`+type);
  }

  getLPById(val: any){
    return this.http.get(`${this.baseUrl}/lp_suggestion?lp_id=`+val);
  }

  createLiveClass(data:any){
    return this.http.post(`${this.baseUrl}/add/live_class`, data);
  }

  updateLiveClass(data:any){
    return this.http.put(`${this.baseUrl}/update_single_class`, data);
  }

  updateGroupLiveClasses(data:any){
    return this.http.put(`${this.baseUrl}/update_single_class`, data);
  }

  deleteLiveClass(classId: number) {
    return this.http.delete(`${this.baseUrl}/add/live_class?classid=` + classId);
  }

  reGenerateRTCTokenFromServer(data: any) {
    return this.http.post(`${this.baseUrl}/class_refresh_token`, data);
  }
  
  getGradesSubjectList() {
    return this.http.get(`${this.baseUrl}/grades_subjects_list`);
  }

  getLanguagesList() {
    return this.http.get(`${this.baseUrl}/all_languages`);
  }

  addUserAvailbilityStatus(data: any) {
    return this.http.post(`${this.baseUrl}/add_member_status`, data);
  }
  
}
