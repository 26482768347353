import { Pipe, PipeTransform } from '@angular/core';
import { USER_POSSIBLE_ONLINE_STATUS } from '@teacherapp_core/common/constants';

@Pipe({
  name: 'getUserStatusDetail'
})
export class GetUserStatusDetailPipe implements PipeTransform {

  userOnlineStatusOptions: any[] = USER_POSSIBLE_ONLINE_STATUS;

  transform(value: unknown, ...args: unknown[]): unknown {
    const _status_matched = this.userOnlineStatusOptions.find((_online_status) => _online_status.id === value);
    if (_status_matched) {
      return _status_matched;
    }
    return null;
  }

}
