import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DataService } from "@teacherapp_core/services/data.service";
import { LocalService } from "@teacherapp_core/services/local.service";
import { Observable, throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    dialogRef = null;

    constructor(
        // public dialog: MatDialog,
        private dataService: DataService,
        private localService: LocalService) {
    }

    errorHandler(error: any) {
        switch (error.status) {
            case 401: {
                console.error('Session Timeout!');
                // this.dialogRef = this.dialog.open(ErrorDialogComponent, {
                //     width: '500px',
                //     data: error
                // });
                return error;
            }
            case 500: {
                console.error('Server Not Responding!');
                // this.dialogRef = this.dialog.open(ErrorDialogComponent, {
                //     width: '500px',
                //     data: error
                // });
                return error;
            }
            case 404: {
                console.error('Requested Resource Not Found!');
                // this.dialogRef = this.dialog.open(ErrorDialogComponent, {
                //     width: '500px',
                //     data: error
                // });
                return error;
            }
            case 405: {
                console.error('Request suspended!');
                return error;
            }
            case 412:
                return error;
                
            case 0: {
                console.error('CORS!');
                return error;
            }
            default: return error;
        }
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const currentUser = this.localService.getUserDataLocalStorage();

        if (currentUser) {
            req = req.clone({
                setHeaders: {
                    Authorization: `Token ${currentUser.token}`,
                    'X-Requested-With': 'XMLHttpRequest'
                }
            });
        }

        return next.handle(req).pipe(
            tap((val) => {
                // console.log('authorization process');
            }),
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401) {
                    this.localService.removeAllUserDataFromLocalStorage();
                    this.dataService.updateCurrentUserData(null);
                    this.localService.redirectToLoginPage();
                }
                return throwError(this.errorHandler(error));
            })
        );
    }
}
