<ngx-ui-loader></ngx-ui-loader>
<section ngxUiLoaderBlurred class="main_Body_Area sidebar-new">
  <div class="overlay-superbook" id="overlay-superbook" (click)="remove_nav()"></div>
  <app-top-navbar-default class="page-header page-header-new" [hidden]="leftSidebarisHidden" [currentUserData]="currentUserData" [currentUserRoleAsStaff]="currentUserRoleAsStaff"></app-top-navbar-default>
  <app-left-navbar-default [hidden]="leftSidebarisHidden" [currentUserData]="currentUserData"></app-left-navbar-default>
 
  <div class="content-wraper superbook-panel">
    <router-outlet></router-outlet>
  </div>
</section>

<ng-container
    *ngComponentOutlet="this.dynamicComponentRefs['login']['component']; ngModuleFactory: this.dynamicComponentRefs['login']['module']"
></ng-container>

<ng-container
    *ngComponentOutlet="this.dynamicComponentRefs['forgot-password']['component']; ngModuleFactory: this.dynamicComponentRefs['forgot-password']['module']"
></ng-container>

<ng-container
    *ngComponentOutlet="this.dynamicComponentRefs['reset-password']['component']; ngModuleFactory: this.dynamicComponentRefs['reset-password']['module']"
></ng-container>

<ng-container
    *ngComponentOutlet="this.dynamicComponentRefs['live-class-preview']['component']; ngModuleFactory: this.dynamicComponentRefs['live-class-preview']['module']"
></ng-container>

<ng-container
    *ngComponentOutlet="this.dynamicComponentRefs['org_selection']['component']; ngModuleFactory: this.dynamicComponentRefs['org_selection']['module']"
></ng-container>

<ng-container
    *ngComponentOutlet="this.dynamicComponentRefs['team_selection']['component']; ngModuleFactory: this.dynamicComponentRefs['team_selection']['module']"
></ng-container>
