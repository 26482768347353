import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatNativeDateModule } from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { ToastMessageBarModule } from '@teacherapp_shared/utils-library/toast-message-bar/toast-message-bar.module';
// import { permissionGroupInitializer } from './initializer/permission-group.initializer';
// import { LocalService } from './services/local.service';
// import { PermissionHandlerService } from './services/permission-handler.service';
import { NgxUiLoaderModule, NgxUiLoaderRouterModule } from 'ngx-ui-loader';
import { NGX_UI_LOADER_DEFAULT_CONFIG } from './common/ngx-ui-loader-loader-config.constant';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatNativeDateModule,
    ToastMessageBarModule,
    NgxUiLoaderModule.forRoot(NGX_UI_LOADER_DEFAULT_CONFIG),
    NgxUiLoaderRouterModule,
  ],
  exports: [
    MatSnackBarModule,
    MatTooltipModule,
    MatNativeDateModule,
    NgxUiLoaderModule,
  ],
  providers: [
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 2500,
        horizontalPosition: 'right',
        verticalPosition: 'top'
      }
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        floatLabel: 'always',
      }
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: permissionGroupInitializer,
    //   deps: [
    //     LocalService,
    //     PermissionHandlerService,
    //   ],
    //   multi: true
    // },
  ]
})
export class CoreModule { }
