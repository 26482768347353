import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarRef, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { TSnackbarData, TSnackbarMode } from '@teacherapp_core/interfaces/snackbar.interface';
import { ToastMessageBarComponent } from './toast-message-bar.component';

@Injectable({
  providedIn: 'root'
})
export class ToastMessageBarService {

  snackbarToastRef: MatSnackBarRef<ToastMessageBarComponent>;

  constructor(
    private matSnackbar: MatSnackBar
  ) { }

  openSnackbar(configData: TSnackbarData, mode: TSnackbarMode = 'SUCCESS', duration: number = 3000, horizontalPosition: MatSnackBarHorizontalPosition = 'right', verticalPosition: MatSnackBarVerticalPosition = 'top') {
    this.snackbarToastRef = this.matSnackbar.openFromComponent(
      ToastMessageBarComponent,
      {
        data: { ...configData, mode },
        panelClass: [ 'c-snackbar', `c-snackbar-${ mode.toLowerCase() }`],
        horizontalPosition,
        verticalPosition,
        duration
      }
    );

    this.snackbarToastRef.afterDismissed().subscribe(
      (response: any) => {
        // console.log('Snackbar Dismissed', response);
      }
    )
  }
}
