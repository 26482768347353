import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CLASS_PAGE_URL_STORAGE_NAME, HOME_PAGE_PATH, QF_Q_COPY_MODE_STORAGE_NAME, USER_DATA_STORAGE_NAME, USER_ORG_DATA_STORAGE_NAME, USER_TEAM_DATA_STORAGE_NAME } from '@teacherapp_core/common/constants';

@Injectable({
  providedIn: 'root'
})
export class LocalService {

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  getUserDataLocalStorage() {
    const localData = localStorage.getItem(USER_DATA_STORAGE_NAME);
    if (localData) {
      return JSON.parse(localData);
    }
    return null;
  }

  getUserIdLocalStorage() {
    const userData = this.getUserDataLocalStorage();
    if (userData && userData.user) {
      return userData.user.id;
    }
    return 0;
  }

  getOrganizationSpecificStaffRole(_org_id: number) {
    const userData = this.getUserDataLocalStorage();
    if (userData && userData.org_data && userData.org_data.length) {
      const _current_org = userData.org_data.find((_org) => _org.id == _org_id);
      if (_current_org) {
        return _current_org.staff_roles;
      }
    }
    return null;
  }

  getOrganizationSpecificStaffId(_org_id: number) {
    const userData = this.getUserDataLocalStorage();
    if (userData && userData.org_data && userData.org_data.length) {
      const _current_org = userData.org_data.find((_org) => _org.id == _org_id);
      if (_current_org) {
        return _current_org.staff_id;
      }
    }
    return 0;
  }

  storeUserDataLocalStorage(userData: any) {
    localStorage.setItem(USER_DATA_STORAGE_NAME, JSON.stringify(userData));
  }

  removeUserDataFromLocalStorage() {
    localStorage.removeItem(USER_DATA_STORAGE_NAME);
  }

  getUserOrgSelectionLocalStorage() {
    const localData = localStorage.getItem(USER_ORG_DATA_STORAGE_NAME);
    if (localData) {
      return JSON.parse(localData);
    }
    return null;
  }

  getSelectedUserOrgIdLocalStorage() {
    const selectedOrgData = this.getUserOrgSelectionLocalStorage();
    if (selectedOrgData) {
      return selectedOrgData.id;
    }
    return 0;
  }

  storeSpecificUserOrgSelectionLocalStorage(_org_id: number) {
    const userData = this.getUserDataLocalStorage();
    if (userData && userData.org_data && userData.org_data.length) {
      const _current_org = userData.org_data.find((_org) => _org.id == _org_id);
      if (_current_org) {
        this.storeUserOrgSelectionLocalStorage(_current_org);
        return;
      }
    }
    this.removeUserOrgSelectionFromLocalStorage();
    return;
  }

  storeUserOrgSelectionLocalStorage(orgData: any) {
    localStorage.setItem(USER_ORG_DATA_STORAGE_NAME, JSON.stringify(orgData));
  }

  removeUserOrgSelectionFromLocalStorage() {
    localStorage.removeItem(USER_ORG_DATA_STORAGE_NAME);
  }

  getUserTeamSelectionLocalStorage() {
    const localData = localStorage.getItem(USER_TEAM_DATA_STORAGE_NAME);
    if (localData) {
      return JSON.parse(localData);
    }
    return null;
  }

  getSelectedUserTeamIdLocalStorage() {
    const selectedTeamData = this.getUserTeamSelectionLocalStorage();
    if (selectedTeamData) {
      return selectedTeamData.id;
    }
    return 0;
  }

  storeUserTeamSelectionLocalStorage(teamData: any) {
    localStorage.setItem(USER_TEAM_DATA_STORAGE_NAME, JSON.stringify(teamData));
  }

  removeUserTeamSelectionFromLocalStorage() {
    localStorage.removeItem(USER_TEAM_DATA_STORAGE_NAME);
  }

  getQFCopySettingSessionStorage() {
    const localData = sessionStorage.getItem(QF_Q_COPY_MODE_STORAGE_NAME);
    if (localData) {
      return JSON.parse(localData);
    }
    return null;
  }

  storeQFCopySettingSessionStorage(teamData: any) {
    sessionStorage.setItem(QF_Q_COPY_MODE_STORAGE_NAME, JSON.stringify(teamData));
  }

  removeQFCopySettingFromSessionStorage() {
    sessionStorage.removeItem(QF_Q_COPY_MODE_STORAGE_NAME);
  }

  removeAllUserDataFromLocalStorage() {
    this.removeUserDataFromLocalStorage();
    this.removeUserOrgSelectionFromLocalStorage();
    this.removeUserTeamSelectionFromLocalStorage();
  }

  checkIsAuthenticated(): boolean {
    if (localStorage.getItem(USER_DATA_STORAGE_NAME)) {
      return true;
    }
    return false;
  }

  async redirectToHomePage() {
    await this.router.navigate(
      [HOME_PAGE_PATH],
      {
        queryParams: { page: null },
        queryParamsHandling: 'merge'
      }
    );
  }

  async redirectToLoginPage() {
    await this.router.navigate(
      [],
      {
        queryParams: { page: 'login' },
        queryParamsHandling: 'merge',
        relativeTo: this.activatedRoute
      }
    );
  }

  async refreshCurrentPage(qParams: any = {}) {
    await this.router.navigate(
      [],
      {
        queryParams: { page: null, ...qParams },
        queryParamsHandling: 'merge',
        relativeTo: this.activatedRoute
      }
    );
  }

  async redirectToSpecificDynamicPage(pageName: string) {
    await this.router.navigate(
      [],
      {
        queryParams: { page: pageName },
        queryParamsHandling: 'merge',
        relativeTo: this.activatedRoute
      }
    );
  }

  removeLastClassfilterPageUrl() {
    localStorage.removeItem(CLASS_PAGE_URL_STORAGE_NAME);
  }

  setLastClassfilterPageUrl(url: string) {
    localStorage.setItem(CLASS_PAGE_URL_STORAGE_NAME,  btoa(url));
  }

  getLastClassfilterPageUrl() {
    if(localStorage.getItem(CLASS_PAGE_URL_STORAGE_NAME)) {
      return atob(localStorage.getItem(CLASS_PAGE_URL_STORAGE_NAME));
    }
    return null;
  }

  refreshEntirePage() {
    location.reload();
  }
}
