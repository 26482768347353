import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { publicDynamicPages } from '@teacherapp_core/common/dynamic-page-settings';
import { LocalService } from '@teacherapp_core/services/local.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PrivateGuard implements CanActivate {

  dynamicPublicPageList = publicDynamicPages;

  constructor(
    private localService: LocalService,
  ) {

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if(this.localService.checkIsAuthenticated()) {
      return true;
    }
    else if(route.queryParams && route.queryParams.page && this.dynamicPublicPageList.indexOf(route.queryParams.page) >= 0) {
      return true;
    }
    else {
      this.localService.redirectToLoginPage();
      return false;
    }
  }
  
}
