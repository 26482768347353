import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopNavbarDefaultComponent } from './top-navbar-default.component';
import { RouterModule } from '@angular/router';
import { MatMenuModule } from '@angular/material/menu';
import { GetRandomColorCodeModule } from '@teacherapp_core/pipes/get-random-color-code/get-random-color-code.module';
import { GetNameInitialModule } from '@teacherapp_core/pipes/get-name-initial/get-name-initial.module';
import { UserAvatarPicUiModule } from '@teacherapp_shared/common-templates/user-avatar-pic-ui/user-avatar-pic-ui.module';

@NgModule({
  declarations: [
    TopNavbarDefaultComponent
  ],
  imports: [
    CommonModule,
    MatMenuModule,
    RouterModule,
    UserAvatarPicUiModule,
    GetRandomColorCodeModule,
    GetNameInitialModule,
  ],
  exports: [
    TopNavbarDefaultComponent
  ]
})
export class TopNavbarDefaultModule { }
