<div class="main-wrapper">
    <div class="page-header-c">
        <div class="head-l">
            <div class="menuBarIco" (click)="show_main_nav()">
                <span class="line"></span>
            </div>
        </div>
        <div class="head-m">
            <div class="h-logo">
                <img src="./assets/images/icons/doerdo-logo-left-panel.png" alt="">
            </div>
        </div>
        <div class="head-r">
            <div class="header-right">
                <div class="user">
                    <app-user-avatar-pic-ui [userInfo]="currentUserData" [userAvatarMode]="'USER_VIEW'" [panelClass]="''"></app-user-avatar-pic-ui>

                    <div class="user-info">
                        <p class="name mb-0">{{ currentUserData?.username | titlecase}} </p>
                        <!-- <span class="designation text-muted">({{ staffRoleEnums[currentUserRoleAsStaff] }}) {{ (userStatusInfo && userStatusInfo.online_status) ? '(' + userStatusInfo.online_status + ')' : '' }}</span> -->
                    </div>


                    <!-- <span class="photo" [ngClass]="(currentUserData && currentUserData.online_status) ? (currentUserData.online_status | lowercase) : ''">
                        <img src="../../../../assets/images/icons/user-icon.png" *ngIf="!currentUserData?.avatar_link">
                        <img [src]="imagePath + currentUserData.avatar_link" *ngIf="currentUserData?.avatar_link">
                    </span> -->

                    <button class="btn--set-online-status" type="button" [matMenuTriggerFor]="onlineStatusViewer">
                        <svg width="800px" height="800px" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19 16a3 3 0 0 1-3 3 3 3 0 0 1-3-3 3 3 0 0 1 3-3 3 3 0 0 1 3 3zm0 13a3 3 0 0 1-3 3 3 3 0 0 1-3-3 3 3 0 0 1 3-3 3 3 0 0 1 3 3zm0-26a3 3 0 0 1-3 3 3 3 0 0 1-3-3 3 3 0 0 1 3-3 3 3 0 0 1 3 3z" fill="currentColor"/>
                        </svg>
                    </button>

                    <mat-menu #onlineStatusViewer="matMenu" class="add-filter-list mat-menu-status-list action-list" xPosition="after">
                        <div class="user mobile-user-info">
                            <app-user-avatar-pic-ui [userInfo]="currentUserData" [userAvatarMode]="'USER_VIEW'" [panelClass]="''"></app-user-avatar-pic-ui>

                            <div class="user-info">
                                <p class="name mb-0">{{ currentUserData?.username | titlecase}} </p>
                            </div>
                        </div>

                        <ng-container *ngFor="let _user_online_status of userOnlineStatusOptions">
                            <div class="list-bx" [class.active]="(currentUserData && currentUserData.online_status) === _user_online_status.id" (click)="doChangeUserOnlineStatus(_user_online_status, memberIndex)">
                                <img [src]="_user_online_status.icon" alt="">
                                {{ _user_online_status.name }}
                            </div>
                        </ng-container>
                    </mat-menu>
                </div>
            </div>
            <a class="btn btn-purple btn-xs login-btn" [routerLink]="['']" [queryParams]="{page: 'login'}" [queryParamsHandling]="'merge'">
                <img src="./assets/images/svg/login-icon.svg" alt="">
                Login
            </a>
            <!-- <a class="btn btn-purple btn-xs login-btn signup" style="margin-right: 6px;">
                <img src="./assets/images/svg/user-plus.svg" alt="">
                Sign up
            </a> -->
        </div>
    </div>
</div>